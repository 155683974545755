import $ from 'jquery';
import blogmenu from './modules/blogmenu';

// Bootstrap (v3) doesn't have proper module support, so we need to make jQuery global and `require` bootstrap
global.$ = global.jQuery = $;
require('bootstrap');

//Initialize everything on document ready
$(document).ready(() => {
  blogmenu.init();
});
