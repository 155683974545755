/*
* JS for megamenu
* CSS styling in header.scss + buttons.scss
*/

'use strict';

import $ from 'jquery';

let blogmenu = (() => {
  const $body = $('body');
  const $header = $('.site-header');
  const $nav = $('.js-primary-nav');

  const mobileBreakpoint = 1024; //breakpoint for mobile navigation

  let mobileThreshold = window.matchMedia('(max-width: ' + (mobileBreakpoint - 1) + 'px)'); //checking if mobile
  let isMobile = mobileThreshold.matches; // boolean for mobile checking

  //Add screen size listener
  mobileThreshold.addListener(checkMobile);

  function checkMobile(e) {
    if(e && e.matches) {
      //Entering mobile
      isMobile = true;
    } else {
      //Leaving mobile
      isMobile = false;
    }
  }

  function init() {
    //Toggle mobile menu
    $('.js-primary-nav-toggle').on('click', function(e) {
      console.log('huutista');
      if(isMobile) {
        const $btn = $(this);
        console.log($nav);
        console.log($header);
        $btn.toggleClass('primary-nav-toggle-open');
        $('.js-primary-nav').toggleClass('primary-nav-open');
        $header.toggleClass('sticky-mobile'); //class to make header fixed
        $('body').toggleClass('no-scroll-mobile');
      }
    });
  }

  return {
    init : init
  };

})();

module.exports = blogmenu;
